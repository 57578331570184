














/**
 * To show a message in data tables when not have items
 */
import Vue from "vue";

export default Vue.extend({
  name: "no-records"
});
